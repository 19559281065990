const API_URL = process.env.REACT_APP_API_URL || "http://localhost:9292";

export const fetchCRMStats = async () => {
  try {
    const url = `${API_URL}/crm`;
    console.log("Fetching CRM stats from:", url);
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `Network response was not ok (${response.status}): ${errorText}`,
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching CRM stats:", error);
    throw error;
  }
};

export const submitLead = async (leadData) => {
  try {
    const response = await fetch(`${API_URL}/leads`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(leadData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error submitting lead:", error);
    throw error;
  }
};
