import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

function SimpleHeader() {
  const { t, i18n } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  return (
    <header className="bg-white bg-opacity-90 shadow-md">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src="/img/hg-color-logo.svg" alt="Helio Gains Logo" className="h-8 w-auto mr-2" />
        </Link>
        <div className="flex items-center gap-4">
          <nav className="hidden md:block">
            <ul className="flex space-x-6">
              <li><a href="#how-it-works" className="text-sm text-tertiary hover:text-secondary transition duration-300">{t('common.navigation.howItWorks')}</a></li>
              <li><a href="#investment-opportunities" className="text-sm text-tertiary hover:text-secondary transition duration-300">{t('common.navigation.invest')}</a></li>
              <li><a href="#sustainability-impact" className="text-sm text-tertiary hover:text-secondary transition duration-300">{t('common.navigation.impact')}</a></li>
              <li><Link to="/login" className="bg-tertiary text-white text-sm px-4 py-2 rounded-sm hover:bg-opacity-90 transition duration-300">{t('common.navigation.login')}</Link></li>
            </ul>
          </nav>
          <div className="flex gap-2">
            <Link 
              to="/en"
              className="p-1 hover:opacity-80 transition-opacity"
              onClick={() => i18n.changeLanguage('en')}
            >
              <img src="/img/flags/en.svg" alt="English" className="w-6 h-6" />
            </Link>
            <Link 
              to="/th"
              className="p-1 hover:opacity-80 transition-opacity"
              onClick={() => i18n.changeLanguage('th')}
            >
              <img src="/img/flags/th.svg" alt="Thai" className="w-6 h-6" />
            </Link>
          </div>
        </div>
      </div>
      {mobileMenuOpen && (
        <nav className="md:hidden bg-white">
          <ul className="flex flex-col items-center py-4">
            <li className="py-2"><a href="#how-it-works" className="text-tertiary hover:text-secondary transition duration-300" onClick={toggleMobileMenu}>{t('navigation.howItWorks')}</a></li>
            <li className="py-2"><a href="#investment-opportunities" className="text-tertiary hover:text-secondary transition duration-300" onClick={toggleMobileMenu}>{t('navigation.invest')}</a></li>
            <li className="py-2"><a href="#sustainability-impact" className="text-tertiary hover:text-secondary transition duration-300" onClick={toggleMobileMenu}>{t('navigation.impact')}</a></li>
            <li className="py-2"><Link to="/login" className="bg-tertiary text-white px-4 py-2 rounded-sm hover:bg-opacity-90 transition duration-300" onClick={toggleMobileMenu}>{t('navigation.login')}</Link></li>
          </ul>
        </nav>
      )}
      <button className="md:hidden text-tertiary absolute right-4 top-4" onClick={toggleMobileMenu}>
        {mobileMenuOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
      </button>
    </header>
  );
}

export default SimpleHeader;
