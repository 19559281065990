// Utility to load gtag
export const loadGtag = () => {
  const existingScript = document.getElementById('gtag');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11492522506';
    script.id = 'gtag';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', 'AW-11492522506');
  }
};

// Utility to send events
export const sendGtagEvent = (action, params = {}) => {
  if (window.gtag) {
    window.gtag('event', action, params);
  }
};
