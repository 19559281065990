import React, { useState, useEffect, useCallback } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import { UserGroupIcon, BoltIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { fetchCRMStats } from '../services/api';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function CRM() {
  console.log('CRM Component Mounted'); // Test log

  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [nextUpdate, setNextUpdate] = useState(60);

  const fetchCRMData = useCallback(async () => {
    try {
      console.log('Starting fetchCRMStats...'); // Log start of fetch
      setLoading(true);
      const rawData = await fetchCRMStats();
      console.log('Raw API Response:', rawData);

      // Transform API data to match required structure
      const transformedData = adaptAPIData(rawData);
      console.log('Transformed Data:', transformedData);

      setStats(transformedData);
    } catch (err) {
      console.error('Fetch error:', err);
      setError(`Failed to fetch CRM data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, []);  // Empty dependency array since it only uses state setters

  useEffect(() => {
    console.log('Fetching CRM stats...'); // Log before fetch
    fetchCRMData(); // Initial fetch
    setLastUpdate(new Date());

    // Set up interval for periodic refresh
    const intervalId = setInterval(() => {
      console.log('Refreshing CRM stats...'); // Log refresh attempt
      fetchCRMData();
      setLastUpdate(new Date());
      setNextUpdate(60); // Reset countdown
    }, 60000);

    // Set up countdown timer
    const countdownId = setInterval(() => {
      setNextUpdate(prev => Math.max(0, prev - 1));
    }, 1000);

    // Cleanup intervals on component unmount
    return () => {
      console.log('Cleaning up CRM refresh interval');
      clearInterval(intervalId);
      clearInterval(countdownId);
    };
  }, [fetchCRMData]);

  const adaptAPIData = (apiData) => {
    try {
      console.log('Starting data adaptation with:', JSON.stringify(apiData, null, 2));

      // Validate input
      if (!apiData) {
        throw new Error('API data is null or undefined');
      }

      // Log each transformation step
      const totalLeadsTransform = {
        id: 1,
        name: 'Total Leads',
        stat: String(apiData.total_leads || 0),
        periodStats: {
          weekly: String(apiData.this_week || 0),
          today: String(apiData.today || 0)
        },
        icon: UserGroupIcon,
        change: `${Math.abs(apiData.percentage_change || 0)}%`,
        changeType: (apiData.percentage_change || 0) >= 0 ? 'increase' : 'decrease'
      };
      console.log('Total Leads transform:', totalLeadsTransform);

      const capacityTransform = {
        id: 2,
        name: 'Capacity Requested',
        stat: `${String(apiData.capacity?.total || 0)} kW`,
        periodStats: {
          weekly: `${String(apiData.capacity?.this_week || 0)} kW`,
          today: `${String(apiData.capacity?.today || 0)} kW`
        },
        icon: BoltIcon,
        change: '0%',
        changeType: 'increase'
      };
      console.log('Capacity transform:', capacityTransform);

      const confirmedLeadsTransform = {
        id: 3,
        name: 'Confirmed Leads',
        stat: String(apiData.confirmed_leads?.total || 0),
        periodStats: {
          weekly: String(apiData.confirmed_leads?.this_week || 0),
          today: String(apiData.confirmed_leads?.today || 0)
        },
        icon: CheckCircleIcon,
        change: `${Math.abs(apiData.confirmed_leads?.percentage_change || 0)}%`,
        changeType: (apiData.confirmed_leads?.percentage_change || 0) >= 0 ? 'increase' : 'decrease'
      };
      console.log('Confirmed Leads transform:', confirmedLeadsTransform);

      const transformedData = [
        totalLeadsTransform,
        capacityTransform,
        confirmedLeadsTransform
      ];

      console.log('Final transformed data:', transformedData);
      return transformedData;

    } catch (error) {
      console.error('Error in adaptAPIData:', error);
      console.error('Failed data structure:', apiData);
      // Return a safe fallback structure
      return [
        {
          id: 1,
          name: 'Total Leads',
          stat: '0',
          periodStats: { weekly: '0', today: '0' },
          icon: UserGroupIcon,
          change: '0%',
          changeType: 'increase'
        },
        {
          id: 2,
          name: 'Capacity Requested',
          stat: '0 kW',
          periodStats: { weekly: '0 kW', today: '0 kW' },
          icon: BoltIcon,
          change: '0%',
          changeType: 'increase'
        },
        {
          id: 3,
          name: 'Confirmed Leads',
          stat: '0',
          periodStats: { weekly: '0', today: '0' },
          icon: CheckCircleIcon,
          change: '0%',
          changeType: 'increase'
        }
      ];
    }
  };


  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-6 text-tertiary">Customer Relationship Management</h1>
        <div className="text-center">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-6 text-tertiary">Customer Relationship Management</h1>
        <div className="text-red-500 text-center">{error}</div>
      </div>
    );
  }
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6 text-tertiary">Customer Relationship Management</h1>

      <div className="flex justify-between items-center mb-4 text-sm text-gray-600">
        <div>
          Last updated: {lastUpdate ? new Date(lastUpdate).toLocaleTimeString() : 'Never'}
        </div>
        <div>
          Next update in: {nextUpdate} seconds
        </div>
      </div>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-sm bg-primary px-4 pb-12 pt-5 shadow-md sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-sm bg-tertiary p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-md font-medium text-gray-600">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-tertiary">{item.stat}</p>
              <p
                className={classNames(
                  item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                  'ml-2 flex items-baseline text-sm font-semibold'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowUpIcon className="h-5 w-5 shrink-0 self-center text-green-500" aria-hidden="true" />
                ) : (
                  <ArrowDownIcon className="h-5 w-5 shrink-0 self-center text-red-500" aria-hidden="true" />
                )}
                <span className="sr-only">
                  {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by
                </span>
                {item.change}
              </p>
              <div className="absolute inset-x-0 bottom-0 bg-secondary bg-opacity-10 px-4 py-3 sm:px-6">
                <div className="flex justify-between divide-x divide-gray-400">
                  <div className="text-center flex-1 pr-4">
                    <p className="text-sm text-gray-600">This Week</p>
                    <p className="text-tertiary font-semibold text-base">{item.periodStats.weekly}</p>
                  </div>
                  <div className="text-center flex-1 pl-4">
                    <p className="text-sm text-gray-600">Today</p>
                    <p className="text-tertiary font-semibold text-base">{item.periodStats.today}</p>
                  </div>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

export default CRM; 
